<template>
  <div class="processing-input-range-polar">
    <l-linear-choice type="tablet">
      <template #textLeft>
        {{ content.l_text }}
      </template>

      <template #default>
        <v-slider
          :answer="PIRM_computed_answer"
          :colors="PIRM_data_colors"
          :disabled="PIRM_computed_isExcludingAll"
          :isPlaceholder="PIRM_data_isPlaceholder"
          :placeholder="PIRM_computed_placeholderIndex"
          :value="PIRM_data_answerIndex"
          :variants="PIRM_computed_purifiedVariants"
          with-label
          with-text
          @input="PIRM_method_setData"
          class="processing-input-range-polar__slider"
        />
      </template>

      <template #textRight>
        {{ content.r_text }}
      </template>
    </l-linear-choice>

    <template v-if="PEIA_computed_excludeAllCode">
      <v-checkbox
        :model-value="PIRM_computed_isExcludingAll"
        :value="PEIA_computed_excludeAllCode"
        @change="PIRM_method_changeExcludingAll"
        class="py-8"
      >
        {{ PEIA_computed_excludeAll.text }}
      </v-checkbox>
    </template>
  </div>
</template>

<script>
import { LLinearChoice } from '@layouts'
import ProcessingInputRangeMixin from './ProcessingInputRangeMixin'

export default {
  name: 'ProcessingInputRangePolar',

  components: {
    LLinearChoice
  },

  mixins: [ProcessingInputRangeMixin],

  computed: {
    currentColor () {
      return this.content.error
        ? 'danger'
        : null
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.processing-input-range-polar {
  margin-top: 1rem;
  margin-bottom: 1rem;

  &__slider {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-right: .5rem;
    margin-left: .5rem;
  }
}
</style>
