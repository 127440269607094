<template>
  <drag-ranging
    :variants="content.variant"
    :error="showError"
    @change="setData"
  />
</template>

<script>
import { DragRanging } from '@components/features'
import Validatable from '@/mixins/validatable'

export default {
  name: 'ProcessingInputSort',

  components: {
    DragRanging
  },

  inject: [
    'sendData',
    'service'
  ],

  mixins: [Validatable],

  props: {
    content: Object
  },

  computed: {
    showError () {
      return this.service.error.isShow && !this.isValid
    }
  },

  methods: {
    setData (dndData) {
      const data = dndData.reduce((acc, curr) => `${acc},${curr.code}`, '')
      const preparedData = data.substring(1, data.length)

      this.$set(this.sendData, this.content.code, preparedData)
      this.checkValidationByCondition(dndData.length === this.content.variant.length)
    }
  }

}
</script>
