<template>
  <div>
    <section
      v-if="content.text!==''"
      class="row align-items-center justify-content-between mb-8"
    >
      <div class="col-12 col-xl-auto order-xl-1 mb-4 mb-4">
        <v-sheet
          rounded
          elevation="2"
          class="px-16 d-flex align-items-center justify-content-between cursor-pointer"
          @click="showInstruction = !showInstruction"
        >
          <div class="py-16 pr-16">
            <b
              class="text-color-primary"
              v-html="instructionTitle"
            />
          </div>

          <div>
            <v-icon
              stroke="primary"
              name="chevron-2"
              size="16"
              :class="{'rotate-270': showInstruction}"
              class="rotate-90"
            />
          </div>
        </v-sheet>
      </div>

      <article
        class="col-12 order-xl-3 mb-8"
        v-if="showInstruction"
      >
        <v-sheet
          rounded
          elevation="2"
          class="p-16"
        >
          <processing-html :content="content.text"></processing-html>
        </v-sheet>
      </article>

      <div class="col-12 col-xl-auto order-xl-2 mb-4 mt-8 mt-lg-0">
        <v-sheet
          rounded
          elevation="2"
          class="p-16 d-flex align-items-center"
        >
          <div class="time-info">
            <v-icon
              stroke="primary"
              name="clock"
              size="24"
              class="time-info__icon"
            />

            <span class="time-info__text">
              {{ $t("processing.contentTable.info.timerOff") }}
            </span>
          </div>
        </v-sheet>
      </div>
    </section>

    <table class="pc-table">
      <tr
        v-for="(row, index) in content.rows"
        :key="index"
        :class="{'pc-table-item_finished': row.status === 'finished'}"
        class="pc-table-item"
      >
        <td class="pc-table__td pc-table__td-numbers">
          <div class="pc-table__bg"></div>
          <div class="pc-table__cell">
            <span class="h4">{{ index + 1 }}</span>
          </div>
        </td>

        <td class="pc-table__td pc-table__td-title">
          <div class="pc-table__cell">
            <span class="h4">{{ row.text }}</span>
          </div>
        </td>

        <td class="pc-table__td pc-table__td-time">
          <div class="pc-table__cell">
            <div
              v-if="row.timer"
              class="row align-items-center flex-nowrap no-gutters mx-sm-16"
            >
              <div class="col-auto pr-4">
                <v-icon
                  stroke="primary"
                  name="clock"
                  size="18"
                  class="time-icon"
                />
              </div>
              <div class="col">
                {{ row.timer | convertTimestampToTime }}
              </div>
            </div>
          </div>
        </td>

        <td class="pc-table__td pc-table__td-actions">
          <div class="pc-table__cell">
            <div
              v-if="row.status === 'finished'"
              class="text-nowrap d-flex align-items-center justify-content-end"
            >
              <v-icon
                name="checked"
                size="12"
                stroke="primary"
                class="mr-8"
              />

              <strong
                class="text-color-primary"
                v-html="content.row_finish_text"
              />
            </div>

            <v-button
              v-if="row.status === 'button'"
              @click="startBlock(row.block_name)"
            >
              <span v-html="content.row_button_text"/>
            </v-button>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import VButton from '@/components/base/VButton'
import ProcessingHtml from './ProcessingHtml'
import VSheet from '@components/base/VSheet/VSheet'
import VIcon from '@components/base/VIcon/VIcon'
import convertTimestampToTime from '../../utils/helpers/convertTimestampToTime'

export default {
  name: 'ProcessingContentTable',
  components: {
    VIcon,
    VSheet,
    ProcessingHtml,
    VButton
  },
  inject: ['service'],
  props: {
    content: Object
  },
  data () {
    return {
      showInstruction: false
    }
  },
  filters: { convertTimestampToTime },
  computed: {
    instructionTitle () {
      return this.showInstruction
        ? this.content.hide_instruction_text
        : this.content.show_instruction_text
    }
  },
  methods: {
    startBlock (blockName) {
      this.service.sendContent({
        block_name: blockName
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~@styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

.pc-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  &__bg {
    position: absolute;
    z-index: 1;
    background-color: #FFFFFF;
    top: 0.125rem;
    right: 0;
    bottom: 0.125rem;
    left: 0;
    border: var(--v-sheet-border);
    border-radius: $border-radius-root;
    @include elevation(2)
  }

  &__cell {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: .5rem;
    min-height: 56px;

    @include media-breakpoint-down(sm) {
      padding: .3rem 1rem;
      min-height: auto;
    }
  }

  &__td {
    padding: 0.125rem 0;

    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }

  &__td-numbers {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .pc-table__cell {
      padding-left: 1rem;
      padding-right: 0;
    }
  }

  &__td-title {
    @include media-breakpoint-down(sm) {
      flex: 100%;
    }
  }

  &__td-actions {
    @include media-breakpoint-down(sm) {
      flex: 1;
    }

    .pc-table__cell {
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }
}

.pc-table-item {
  position: relative;
  transform: translateY(0); // хак для старых браузеров

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-wrap: wrap;
  }

  &_finished {
    .pc-table__bg {
      box-shadow: none;
      border: 1px solid cl(border);
    }
  }
}

.time-info {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: .5rem;
    opacity: 0.5
  }

  &__text {
    display: inline-block;
  }
}

.time-icon {
  position: relative;
  top: -3px;
}
</style>
