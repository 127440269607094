<template>
  <section>
    <div
      v-show="showTextHint"
      class="row"
    >
      <div class="col">
        <processing-html :content="content.text"/>
      </div>
    </div>

    <div
      v-if="showTextHintButton"
      class="row mb-32"
    >
      <div class="col">
        <v-button
          link
          @click="showTextHint = !showTextHint"
        >
          {{
            showTextHint
              ? $t("processing.input.hideInstruction")
              : $t("processing.input.showInstruction")
          }}

          <v-icon
            stroke="primary"
            name="chevron-2"
            size="12"
            :class="{'rotate-270': showTextHint}"
            class="rotate-90 ml-8"
          />
        </v-button>
      </div>
    </div>

    <component
      v-if="!componentNotFound"
      :is="componentName"
      :content="content"
      :key="content.code"
      class="mb-40"
    />
  </section>
</template>

<script>
import { capitalize } from '@/filters'
import { VButton, VIcon } from '@components/base'
import ProcessingHtml from '@views/Processing/ProcessingHtml'
import * as ProcessingInput from '@views/Processing/ProcessingInput'

export default {
  name: 'ProcessingInput',

  components: {
    VIcon,
    VButton,
    ProcessingHtml,
    ...ProcessingInput
  },

  props: {
    content: Object
  },

  data: () => ({
    showTextHint: true,
    showTextHintButton: false
  }),

  watch: {
    componentName () {
      this.setTextHintState()
    }
  },

  computed: {
    componentName () {
      return `ProcessingInput${capitalize(this.content.type)}${capitalize(this.content.subtype)}`
    },
    componentNotFound () {
      const hasComponent = Object.keys(this.$options.components).includes(this.componentName)

      if (!hasComponent) {
        console.error(`Компонент c именем ${this.componentName} не существует.`)
      }

      return !hasComponent
    }
  },

  mounted () {
    this.setTextHintState()
  },

  methods: {
    /**
     * Управление состоянием текстового сообщения наверху мостлиста
     */
    setTextHintState () {
      if (
        this.componentName === 'ProcessingInputLikertMostleast' ||
        this.componentName === 'ProcessingInputLikertBouncing'
      ) {
        this.showTextHintButton = true
      } else if (
        this.componentName === 'ProcessingInputRadioMedia' ||
        this.componentName === 'ProcessingInputCheckboxMedia'
      ) {
        this.showTextHint = false
        this.showTextHintButton = false
      } else {
        this.showTextHint = !!this.content.text
        this.showTextHintButton = false
      }
    }
  }
}
</script>
