<template>
  <div class="row">
    <div
      v-for="variant in content.variant"
      :key="variant.code"
      class="col-12"
    >
      <card-button-radio
        :model-value="PIRM_computed_answer"
        :value="variant.code"
        :error="variant.error"
        :disabled="variant.disabled"
        @change="PIRM_methods_setData"
      >
        <span v-html="variant.text"/>
      </card-button-radio>
    </div>
  </div>
</template>

<script>
import CardButtonRadio from '@components/features/CardButtonRadio/CardButtonRadio'
import ProcessingInputRadioMixin from './ProcessingInputRadioMixin'

export default {
  name: 'ProcessingInputRadio',

  components: {
    CardButtonRadio
  },

  mixins: [
    ProcessingInputRadioMixin
  ]
}
</script>
