<template functional>
  <div class="row mb-24">
    <div class="col">
      <img
        :src="props.content"
        class="question-img"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessingMedia',

  props: {
    content: String
  }
}
</script>
