<template>
  <v-table class="text-size-12">
    <template #thead>
      <v-table-tr
          class="cursor-pointer"
          @click="isOpen = !isOpen"
      >
        <v-table-th colspan="2" class="bg-color-primary">
          <div class="row text-color-light">
            <div
                v-html="content.head"
                class="col text-left"
            />

            <div class="col-auto">
              <v-icon-svg
                  class="a-flip-y svg-stroke-light"
                  :class="{'a-flip-y_active': isOpen}"
                  fill="none"
                  view-box="0 0 11 5"
                  title="svg-icon-chevron"
                  width="12"
                  height="12"
              >
                <svg-icon-chevron/>
              </v-icon-svg>
            </div>
          </div>
        </v-table-th>
      </v-table-tr>
    </template>

    <template
        v-if="isOpen"
        #default
    >
      <v-table-tr
          v-for="(tr, i) of content.trs"
          :key="i"
          :style="{opacity: tr.selected ? '1' : '0.5'}"
          :active="tr.selected"
      >
        <v-table-td
            v-html="tr.text"
        />

        <v-table-td
            v-html="tr.rank"
            class="text-center text-size-16 text-bold"
            style="min-width: 6rem"
        />
      </v-table-tr>
    </template>
  </v-table>
</template>

<script>
import {
  // VAvatar,
  VIconSvg,
  VTable,
  VTableTd,
  VTableTh,
  VTableTr
} from '@components/base'
import SvgIconChevron from '@components/icons/SvgIconChevron'

export default {
  name: 'ProcessingProcess51Tab2Table',

  components: {
    SvgIconChevron,
    // VAvatar,
    VIconSvg,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr
  },

  props: {
    content: Object
  },

  data () {
    return {
      isOpen: true
    }
  }
}
</script>
