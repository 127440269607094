<template>
  <table-radio
    :show-warnings="PILM_computed_showWarning"
    :values="sendData[content.code]"
    :variant-col="content.rank_variant"
    :variant-row="content.variant"
    @change="PILM_methods_setData"
    completion
    unique
  >
    <template #th-first>
      <span v-html="content.placeholder"/>
    </template>
  </table-radio>
</template>

<script>
import ProcessingInputLikertMixin from './ProcessingInputLikertMixin'

export default {
  name: 'ProcessingInputLikertBouncing',

  mixins: [ProcessingInputLikertMixin]
}
</script>
