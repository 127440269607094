<template>
  <v-sheet
      outlined
      elevation="10"
      :class="{
        'py-lg-32 pr-lg-32': content.size !== 'small',
        'py-lg-24 pr-lg-24': content.size === 'small',
        'cursor-pointer': !!request
      }"
      @click="doRequest"
      class="py-16 pl-0 pl-lg-16 pr-16"
  >
    <div class="row">
      <div class="col-12 col-md-auto">
        <div class="row">
          <div class="col-4 col-md-auto">
            <div :class="content.size === 'small' ? 'evraz-mentor-avatar' : 'evraz-avatar'">
              <img
                  :alt="content.name"
                  :src="imgUrl"
              />
            </div>
          </div>
          <div class="col d-md-none">
            <p
                v-html="content.name"
                class="h2 text-bold text-color-primary"
            />

            <v-divider v-if="content.size !== 'small'"/>
          </div>
        </div>
      </div>

      <div class="col pl-24 pl-md-16">
        <div class="row d-none d-md-flex">
          <div class="col">
            <p
                v-html="content.name"
                class="h2 text-bold text-color-primary"
            />
            <v-divider v-if="content.size !== 'small'"/>
          </div>
        </div>

        <div class="row">
          <div
              v-for="(item, index) in content.fields"
              :key="item.id"
              :class="{'col-lg-6': index !== 0}"
              class="col-12 mt-8 mt-lg-16"
          >
            <p class="mb-4 text-color-default text-size-14" v-html="item.title">
            <p class="mb-0" v-html="item.text"/>
          </div>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { VDivider, VSheet } from '@components/base'
import {
  ProcessingImgMixin,
  ProcessingRequestMixin
} from '@views/Processing/mixins'

export default {
  name: 'ProcessingRespondentInfoLine',

  components: { VDivider, VSheet },

  mixins: [
    ProcessingImgMixin,
    ProcessingRequestMixin
  ],

  props: {
    content: Object
  }
}
</script>

<style lang="scss">

.evraz-avatar,
.evraz-mentor-avatar {
  position: relative;
  display: flex;
  padding-left: 1rem;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 1rem;
    height: 3rem;
    background: #fcb53b;
    background: linear-gradient(0deg, #fcb53b 33%, #f57f29 33%, #f57f29 66%, #e32213 66%);
  }

  > img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.evraz-avatar {
  > img {
    max-width: 11rem;
    background-color: white;
  }
}

.evraz-mentor-avatar {
  > img {
    max-height: 6rem;
    max-width: 4rem;
    background-color: white;
  }
}
</style>
