<template>
  <div>
    <v-slider
      :answer="PIRM_computed_answer"
      :colors="PIRM_data_colors"
      :disabled="PIRM_computed_isExcludingAll"
      :isPlaceholder="PIRM_data_isPlaceholder"
      :placeholder="PIRM_computed_placeholderIndex"
      :value="PIRM_data_answerIndex"
      :variants="PIRM_computed_purifiedVariants"
      with-label
      with-text
      @input="PIRM_method_setData"
      class="px-16"
    />

    <template v-if="PEIA_computed_excludeAllCode">
      <v-checkbox
        :model-value="PIRM_computed_isExcludingAll"
        :value="PEIA_computed_excludeAllCode"
        @change="PIRM_method_changeExcludingAll"
        class="py-8"
      >
        {{ PEIA_computed_excludeAll.text }}
      </v-checkbox>
    </template>
  </div>
</template>

<script>

import ProcessingInputRangeMixin from './ProcessingInputRangeMixin'

export default {
  name: 'ProcessingInputRange',

  mixins: [ProcessingInputRangeMixin]
}
</script>
