<template>
  <div>
    <treeselect
      :clearable="false"
      :disable-branch-nodes="onlyLeaf"
      :multiple="!!content.multiple"
      :noOptionsText="$t('processing.input.tree.noResults')"
      :noResultsText="$t('processing.input.tree.noOptions')"
      :options="options"
      :placeholder="content.placeholder || ''"
      :value="value"
      disableFuzzyMatching
      :defaultExpandLevel="content.expandLevel || 0"
      :flat="!content.groupedByParent && !!content.multiple"
      :valueConsistsOf="content.groupedByParent ? 'ALL' : 'BRANCH_PRIORITY'"
      @input="setData"
      :class="{'vue-treeselect_is_error': isError}"
    >
      <template #value-label="{ node }">
        <span v-tooltip="getHierarchy(node)">
          {{ node.raw.label }}
        </span>
      </template>
    </treeselect>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import { Validatable, ValidatableMultiple } from '@/mixins'
import { getType } from '@/utils/helpers'
import { Tooltip } from '@directives'

/**
 * #Орг.стурктура (Select) - опции
 *
 * @typedef {Object} TreeSelectOptions
 *
 * @property {string} code - id вопроса
 *
 * @property {boolean} onlyLeaf - Задаёт поведение для доступного уровня выбора.
 * onlyLeaf = true, то для выбора доступны только листовой узел (единица орг структуры, у которой нет дочерних элементов)
 * onlyLeaf = false, то для выбора доступны любые узлы(уровни) дерева.
 *
 * @property {boolean} groupedByParent - При параметре onlyLeaf = true параметр groupedByParent игнорируется
 * groupedByParent = true, то при выборе родительского элемента выбираются все дочернии элементы. Далее можно снять выбор с дочерних элементов независимо.
 * groupedByParent = false, то каждый элемент дерева на любом уровне выбирается независимо от других.
 *
 * @property {number} expandLevel - Регулирует видимость вложенных уровней.
 *
 * @property {boolean} multiple - Возможность выбора нескольких вариантов
 *
 * @property {number} multipleMin - Минимальное число вариантов, которое нужно выбрать. Если значение параметра “0”,
 * он игнорируется.
 *
 * @property {number} multipleMax - Максимальное число вариантов, которое можно выбрать. Если значение параметра “0”,
 * он игнорируется.
 *
 * @property {boolean} required - Делает вопрос обязательным для заполнения.
 *
 * @property {string} errorText - Текст ошибки, который будет отображен, если вопрос является
 * обязательным (Required = true) и респондент жмет кнопку "Далее" не предоставив ответ.
 *
 * @property {string} placeholder - замещающий текст поля ввода
 *
 * @property options - опции
 *
 */

/**
 * Орг.стурктура (Select)
 * @link https://ecopsy.atlassian.net/wiki/spaces/LKS/pages/2010939395#%D0%9E%D1%80%D0%B3.%D1%81%D1%82%D1%83%D1%80%D0%BA%D1%82%D1%83%D1%80%D0%B0-(Select)
 */
export default {
  name: 'ProcessingInputTreeSelect',

  components: {
    Treeselect
  },

  inject: [
    'sendData',
    'sendDataAjax',
    'service'
  ],

  directives: {
    Tooltip
  },

  mixins: [
    Validatable,
    ValidatableMultiple
  ],

  /**
   * @property {TreeSelectOptions} content - Опции
   */
  props: ['content'],

  data () {
    return {
      isError: false
    }
  },

  watch: {
    'service.error.isShow' () {
      if (this.isValid && this.service.error.isShow) return

      this.isError = this.service.error.isShow
    }
  },

  computed: {
    options () {
      return this.content.options
        ? [this.content.options]
        : []
    },

    value () {
      return this.sendData[this.content.code]
    },

    onlyLeaf () {
      return this.content.onlyLeaf === undefined
        ? true
        : !!this.content.onlyLeaf
    }
  },

  mounted () {
    this.doValidation()
  },

  methods: {
    getHierarchy: (node) => {
      const addPath = (node, path) => {
        if (node.parentNode) {
          return addPath(node.parentNode, ` / ${node.label}${path}`)
        } else {
          return `${node.label}${path}`
        }
      }

      return addPath(node, '')
    },

    setData (values) {
      if (values === undefined || values === null) {
        values = []
      } else if (getType(values) !== 'array') {
        values = [values]
      }

      if (JSON.stringify(values) === JSON.stringify(this.value)) return

      if ((!values.length || values[0] === null)) {
        if (
          this.service.validation.mustPass.includes(`${this.content.code}`) &&
          this.service.validation.state[this.content.code]
        ) {
          this.$set(this.sendData, this.content.code, [])
          this.$set(this.service.validation.state, this.content.code, false)
        }
      } else {
        this.$set(this.sendData, this.content.code, values)
      }

      // подгрузка ajax, если есть
      if (this.content.trigger) {
        this.$set(this.sendDataAjax, 'trigger', this.content.trigger)
        this.$set(this.sendDataAjax, 'value', values)
        this.service.getContentAjax()
      }

      this.doValidation()
    },

    doValidation () {
      if (!this.checkMultipleMinMax()) return
      this.checkValidationByCondition(this.value.length)
    }
  }
}
</script>
