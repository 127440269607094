<template>
  <v-field :error="PTM_computed_isError">
    <v-input
      :value="PTM_computed_value"
      v-bind="attributes"
      @input="PTM_methods_setData"
    />
  </v-field>
</template>

<script>
import ProcessingInputTextMixin from './ProcessingInputTextMixin'
import { VInput } from '@components/base/VField'

export default {
  name: 'ProcessingInputTextNumber',

  components: {
    VInput
  },

  mixins: [ProcessingInputTextMixin],

  computed: {
    attributes () {
      return {
        inputmode: 'numeric',
        placeholder: this.content.placeholder,
        validation: this.numValidation
      }
    }
  },

  methods: {
    numValidation (value) {
      if (Number.isInteger(this.content.step)) {
        // Удаляем все символы, кроме цифр
        value = value.replace(/\D/g, '')

        // Удаляем ведущие нули, если они не единственные в строке
        value = value.replace(/^0+(?=\d)/, '')
      } else {
        // Удаляем все символы, кроме цифр, знаков + - и точки
        value = value.replace(/[^\d.-]/g, '')

        // Удаляем лишние знаки + и -, если они не в начале строки
        value = value.replace(/([+-])(?=[+-])/g, '')

        // Удаляем лишние точки, оставляем только первую, если таковая имеется
        value = value.replace(/\.(?=.*\.)/g, '')

        // Удаляем нули в начале строки, если они не за точкой
        value = value.replace(/^(-?)0+(?=\d)/, '$1')

        // Если после числа есть '-', удаляем его
        value = value.replace(/(\d+)-/, '$1')

        // Если строка начинается с точки, добавляем 0 в начале
        if (value.startsWith('.')) {
          value = '0' + value
        }

        // Если строка начинается с минус точки, д.обавляем -0 в начале
        if (value.startsWith('-.')) {
          value = value.replace(/^-\.$/, '-0.')
        }
      }

      return this.replaceMinMax(value)
    },
    PTM_methods_checkValidation (value) {
      // TODO вынести проверки на число и min max в отдельный миксин
      this.checkValidationByCondition(!!value && this.isNumber(value))
    },
    isNumber (value) {
      return !isNaN(+this.numberNormalize(value))
    },
    numberNormalize (value) {
      return `${value}`.replace(',', '.')
    },
    replaceMinMax (value) {
      // TODO убрать после того как будут внесены изменения на бэк по дефолтовым значениям
      if (
        (this.content.max_val === 0 && this.content.min_val === 0) ||
        (this.content.max_val < this.content.min_val) ||
        !this.content.required ||
        value === ''
      ) return value

      const normalizedValue = this.numberNormalize(value)

      if (normalizedValue > this.content.max_val) {
        value = this.content.max_val
      }

      if (normalizedValue < this.content.min_val) {
        value = this.content.min_val
      }

      return value
    }
  }
}
</script>
