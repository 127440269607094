<template>
  <linear-choice
    :value="answer"
    :options="content.variant"
    :color="currentColor"
  >
    <template #textLeft>
      <span v-html="content.l_text"/>
    </template>

    <template #default>
      <v-linear-radio
        :value="answer"
        :variants="content.variant"
        :color="currentColor"
        type360
        @input="setData"
      />
    </template>

    <template #textRight>
      <span v-html="content.r_text"/>
    </template>
  </linear-choice>
</template>

<script>
import { LinearChoice } from '@components/features'
import { VLinearRadio } from '@components/base'
import Validatable from '@mixins/validatable'

export default {
  name: 'ProcessingInputRange',

  components: {
    LinearChoice,
    VLinearRadio
  },

  inject: [
    'sendData',
    'service'
  ],

  mixins: [Validatable],

  props: {
    content: Object
  },

  data () {
    return {
      isError: false
    }
  },

  mounted () {
    this.checkValidationByCondition(!!this.answer)
  },

  watch: {
    'service.error.isShow' () {
      if (this.isValid && this.service.error.isShow) return

      this.isError = this.service.error.isShow
    }
  },

  computed: {
    answer () {
      return this.sendData[this.content.code][0] || ''
    },
    currentColor () {
      return this.isError
        ? 'danger'
        : null
    }
  },

  methods: {
    setData (newAnswer) {
      this.$set(this.sendData, this.content.code, [newAnswer])
      this.checkValidationByCondition(!!newAnswer)
    }
  }
}
</script>
