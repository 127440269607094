<template>
  <section>
    <section class="row mt-24">
      <div class="col">
        <v-sheet
          rounded="lg"
          elevation="4"
          class="py-32 px-16 p-xl-32"
        >
          <div class="row">
            <div class="col">
              <div
                v-show="showInstruction"
                v-html="content.instruction"
                class="pb-24"
              />

              <v-button
                @click="showInstruction = !showInstruction"
                link
              >
                {{ showInstruction ? 'Скрыть' : 'Посмотреть' }} инструкцию
                <v-icon-svg
                  class="a-flip-y svg-stroke-ui-main-1 ml-8"
                  :class="{'a-flip-y_active': showInstruction}"
                  style="width: .75rem; height: .75rem;"
                  fill="none"
                  view-box="0 0 11 5"
                  width="12"
                  height="12"
                >
                  <svg-icon-chevron/>
                </v-icon-svg>
              </v-button>
            </div>
          </div>
        </v-sheet>
      </div>
    </section>

    <section class="row justify-content-between align-items-center mt-40">
      <div class="col-auto">
        <v-icon-svg
          class="mr-12"
          fill="#FFCA2B"
          view-box="0 0 40 32"
          title="svg-icon-chevron"
          width="40"
          height="32"
        >
          <svg-icon360-crown/>
        </v-icon-svg>

        <b>90</b>
      </div>

      <div class="col-auto">
        <v-icon-svg
          class="svg-fill-ui-main-1 mr-12"
          fill="#FFCA2B"
          view-box="0 0 24 24"
          title="svg-icon-chevron"
          width="24"
          height="24"
        >
          <svg-icon360-sert/>
        </v-icon-svg>

        <b class="text-color-primary">Мой отчет 360°</b>
      </div>
    </section>

    <section class="row mt-32">
      <div class="col">
        <v-tabs>
          <v-tab
            v-for="(tab, index) in content.tabs"
            :key="index"
            :label="tab.name"
            class="py-32 px-16 p-xl-32"
          >
            <div class="row align-items-center mb-40">
              <div class="col">
                <h2>
                  {{ tab.name }}
                </h2>
              </div>

              <div class="col-auto">
                <button
                  type="button"
                  class="btn-add-user"
                  @click="modal.isShow = true"
                >
                    <span class="btn-add-user__icon">
                      <v-icon-svg
                        stroke="none"
                        fill="light"
                        view-box="0 0 24 24"
                        width="24"
                        height="24"
                        style="top: 0"
                      >
                        <svg-icon360-add-user/>
                      </v-icon-svg>
                    </span>

                  <span class="btn-add-user__text">
                      Добавить коллег<br/>для оценки
                    </span>
                </button>
              </div>
            </div>

            <div
              v-for="tabGroup of tab.groups"
              :key="tabGroup.name"
            >
              <h4
                v-if="!tabGroup.hide_name_flag && tabGroup.links.length > 0"
                class="mt-32 mb-12 text-color-text-secondary"
              >
                {{ tabGroup.name }}
              </h4>

              <v-list
                underlined
              >
                <v-list-item
                  v-for="(tabGroupLink, index) of tabGroup.links"
                  :ripple="false"
                  :key="index"
                  :class="{
                      'v-list-item_is_checked': status[tabGroupLink.uuid] === 'end',
                      'v-list-item_is_clickable': tab.clickable
                    }"
                  class="px-16"
                >
                  <div class="text-bold">
                    {{ tabGroupLink.text }}
                  </div>

                  <template
                    v-if="tab.clickable"
                    slot="end"
                  >
                    <div class="list-button">
                      <div
                        v-if="status[tabGroupLink.uuid] === 'end'"
                        title="Опрос завершен"
                      >
                        <v-icon-svg
                          stroke="none"
                          fill="var(--color-primary)"
                          view-box="0 0 24 13"
                          width="24"
                          height="24"
                        >
                          <svg-icon360-checked/>
                        </v-icon-svg>
                      </div>

                      <v-button
                        v-if="status[tabGroupLink.uuid] === 'not started'"
                        round
                        icon
                        size="sm"
                        color="success"
                        title="Заполнить опросник"
                      >
                        <v-icon-svg
                          style="left: 1px; top: 0; width: .75rem; height: .75rem;"
                          stroke="none"
                          fill="light"
                          view-box="0 0 9 12"
                          width="12"
                          height="12"
                        >
                          <svg-icon360-play/>
                        </v-icon-svg>
                      </v-button>

                      <v-button
                        v-if="status[tabGroupLink.uuid] === 'progress'"
                        round
                        icon
                        size="sm"
                        color="warning"
                        title="Продолжить"
                      >
                        <v-icon-svg
                          style="top: 0; width: .75rem; height: .75rem;"
                          stroke="light"
                          fill="none"
                          view-box="0 0 8 12"
                          width="12"
                          height="12"
                        >
                          <svg-icon360-pause/>
                        </v-icon-svg>
                      </v-button>
                    </div>

                    <div class="list-button">
                      <v-button
                        v-if="status[tabGroupLink.uuid] !== 'end'"
                        round
                        icon
                        size="sm"
                        transparent
                        color="secondary"
                        title="Удалить"
                      >
                        <v-icon-svg
                          style="top: 0; width: .75rem; height: .75rem;"
                          stroke="none"
                          fill="var(--color-border)"
                          view-box="0 0 9 9"
                          width="12"
                          height="12"
                        >
                          <svg-icon-x/>
                        </v-icon-svg>
                      </v-button>
                    </div>
                  </template>
                </v-list-item>
              </v-list>
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </section>

    <v-modal
      v-model="modal.isShow"
      no-indent
    >
      <div
        class="px-24 pt-24"
        slot="header"
      >
        <div class="row mb-16">
          <div class="col">
            <h3>
              Выберите респонента
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <v-field>
              <v-input
                placeholder="Поиск"
                v-model="modal.list.search"
              />
            </v-field>
          </div>
        </div>
      </div>

      <v-list>
        <v-list-item
          v-for="{id, text} in filteredUsers"
          :key="id"
          :active="modal.checkedUsersId.includes(id)"
          clickable
          no-indent
        >
          <v-checkbox
            v-model="modal.checkedUsersId"
            :value="id"
            class="px-24 py-8"
          >
            {{ text }}
          </v-checkbox>
        </v-list-item>
      </v-list>

      <p
        v-if="filteredUsers.length === 0"
        class="p-24 text-center"
      >
        Поиск не дал результатов
      </p>

      <div
        slot="footer"
        class="p-24"
      >
        <div
          v-if="checkedUsers.length > 0"
          class="row mb-16"
        >
          <div class="col-12 text-size-12 text-color-text-secondary pb-4">Отмечены</div>

          <div class="col">
            <v-button
              v-for="{id, text} in checkedUsers"
              :key="id"
              rounded="pill"
              color="default"
              size="sm"
              class="mr-4 mb-4"
              @click="modal.list.search = text"
            >
              {{ text }}
            </v-button>
          </div>
        </div>

        <div class="row">
          <div class="col-auto">
            <v-button
              :disabled="modal.checkedUsersId.length === 0"
              @click="addUsers"
            >
              Добавить
            </v-button>
          </div>
          <div class="col-auto">
            <v-button
              color="default"
              outlined
              @click="modal.isShow = false"
            >
              Отмена
            </v-button>
          </div>
        </div>
      </div>
    </v-modal>
  </section>
</template>

<script>
import { getSessionStatusesApi, createRespondentPairBySession } from '@/api'
import {
  VButton,
  VIconSvg,
  VList,
  VListItem,
  VSheet,
  VTab,
  VTabs
} from '@components/base'
import SvgIconChevron from '@components/icons/SvgIconChevron'
import SvgIcon360Crown from '@components/icons/360/SvgIcon360Crown'
import SvgIcon360Sert from '@components/icons/360/SvgIcon360Sert'
import SvgIcon360Checked from '@components/icons/360/SvgIcon360Checked'
import SvgIcon360Play from '@components/icons/360/SvgIcon360Play'
import SvgIcon360Pause from '@components/icons/360/SvgIcon360Pause'
import SvgIcon360AddUser from '@components/icons/360/SvgIcon360AddUser'
// import SvgIcon360Lock from '@components/icons/360/SvgIcon360Lock'
import SvgIconX from '@components/icons/SvgIconX'
import VModal from '@components/base/VModal/VModal'
import VCheckbox from '@components/base/VCheckbox/VCheckbox'
import VField from '@components/base/VField/VField'
import VInput from '@components/base/VField/VInput/VInput'

export default {
  name: 'ProcessingScreen360',

  components: {
    VInput,
    VField,
    VCheckbox,
    VModal,
    VSheet,
    SvgIcon360AddUser,
    SvgIcon360Checked,
    SvgIcon360Crown,
    SvgIcon360Pause,
    SvgIcon360Play,
    // SvgIcon360Lock,
    SvgIcon360Sert,
    SvgIconX,
    SvgIconChevron,
    VButton,
    VIconSvg,
    VList,
    VListItem,
    VTab,
    VTabs
  },

  inject: ['service'],

  props: {
    content: Object
  },

  data () {
    return {
      modal: {
        checkedUsersId: [],
        list: {
          search: '',
          items: this.service.unrendered[0].content.variant
        },
        isShow: false
      },
      showInstruction: false,
      status: []
    }
  },

  watch: {
    'service.unrendered': {
      deep: true,
      'handler' () {
        this.modal.list.items = this.service.unrendered[0].content.variant
      }
    }
  },

  computed: {
    filteredUsers () {
      return this.modal.list.items.filter(({ text }) => {
        return text.toLowerCase().indexOf(this.modal.list.search.toLowerCase()) > -1
      })
    },
    checkedUsers () {
      return this.modal.list.items.filter(({ id }) => {
        return this.modal.checkedUsersId.includes(id)
      })
    }
  },

  created () {
    getSessionStatusesApi(this.service.event)
      .then(response => {
        this.status = response.data
      })
  },

  methods: {
    addUsers () {
      // eslint-disable-next-line camelcase
      const { target_id } = this.service.unrendered[0].content
      const requests = this.checkedUsers.map(({ id }) => {
        return createRespondentPairBySession({
          session_uuid: this.service.event,
          evaluated_id: id,
          group_360_id: target_id
        })
      })

      Promise.all(requests)
        .then(() => this.service.getContent())
        .then(() => {
          // на случай если не все запросы прошли
          this.modal.checkedUsersId = this.modal.list.items.reduce((result, { id }) => {
            if (this.modal.checkedUsersId.includes(id)) {
              result.push(id)
            }

            return result
          }, [])
        })
        .catch(error => console.error(error))
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.list-button {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 3rem;
}

.btn-add-user {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: br(null);
  cursor: pointer;
  transition: color .3s ease;

  &:hover {
    color: cl(primary);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: cl(primary);
    border-radius: br(null);
  }

  &__text {
    padding: 0 .75rem;
    text-align: left;
    font: 600 1rem $font-primary;
  }
}
</style>
