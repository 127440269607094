<template>
  <div>
    <div class="printed-logo">
      <img
        :src="logo.src"
        alt=""
        class="printed-logo__img"
        draggable="false"
      />
    </div>

    <l-content-widget
      :sticky="widget.isSticky"
      fullHeight
      noIdent
    >
      <template #widget>
        <l-widget
          fixed-height
          no-ident
        >
          <template #header>
            <div class="nlmk-ca-widget-header">
              <b class="">{{ content.name }}</b>
            </div>
          </template>

          <template #default>
            <div class="nlmk-ca-widget-main">
              <ul class="ul d-none d-xl-block">
                <li
                  class="pb-16"
                  v-for="(content, i) in currentTab.content"
                  :key="i"
                >
                  <a
                    class="link-standard"
                    :href="`#${content.competency}`"
                  >
                    {{ content.competency }}
                  </a>

                  <div
                    v-if="content.widgetRank !== undefined && content.widgetAnswerCount !== undefined"
                    class="row text-color-default"
                  >
                    <div class="col">Оценки {{ content.widgetAnswerCount }}/{{ content.tables.length }}</div>
                    <div class="col">Итог {{ content.widgetRank }}</div>
                  </div>

                  <ul
                    v-if="content.subMenu && content.subMenu.length > 0"
                    class="ol pl-32 pt-16"
                  >
                    <li
                      class="pb-8"
                      v-for="({head}, j) in content.subMenu"
                      :key="j"
                    >
                      <a
                        class="link-standard"
                        :href="`#${head}`"
                      >
                        {{ head }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>

              <v-select
                :value="widget.selectedAnchor"
                :options="currentTab.content"
                :clearable="false"
                :filterable="false"
                :searchable="false"
                label="competency"
                @input="changeSelect"
                class="d-xl-none v-select_type_widget"
              />
            </div>
          </template>

          <template
            v-if="currentIndex === 2"
            #footer
          >
            <div class="nlmk-ca-widget-prefooter">
              <b>Алгоритм сбора информации:</b>

              <ul class="ul mt-8">
                <li><span class="text-color-primary">S</span>ituation — Пример ситуации</li>
                <li><span class="text-color-primary">T</span>ask — Какая стояла задача</li>
                <li><span class="text-color-primary">A</span>ction — Что предпринимал</li>
                <li><span class="text-color-primary">R</span>esults — Какой результат</li>
              </ul>
            </div>
            <div
              v-if="!isSent"
              :class="{'nlmk-ca-widget-footer_is_checked': isChecked}"
              @click="beforeSendData"
              class="nlmk-ca-widget-footer"
            >
              Завершить оценку
            </div>
          </template>
        </l-widget>
      </template>

      <template #default>
        <v-tabs
          position="right"
          :value="currentIndex"
          @input="setCurrentId"
        >
          <template
            v-for="(tab, index) in tabs"
            v-slot:[`slot-${index}`]="{ isActive }"
          >
            <div
              :key="index"
              class="d-flex align-items-center justify-content-center"
            >
              <v-icon-svg
                :class="`v-icon-svg_mode_${index}`"
                view-box="0 0 24 24"
                width="24"
                height="24"
              >
                <component :is="`SvgIconNlmk${tab.icon}`"/>
              </v-icon-svg>

              <span class="ml-12" v-if="isActive">
              {{ tab.name }}
            </span>
            </div>
          </template>

          <component
            v-for="(tab, index) in tabs"
            :key="index"
            :is="`ProcessingProcess51Tab${tab.id}`"
            :isSent="isSent"
            :isFirstRun="isFirstRun"
            :tab="tab"
            v-on:tabchange="checkData"
            class="px-32 py-16 "
          />
        </v-tabs>

        <v-modal
          v-model="widget.showModal"
        >
          <h4 slot="header">Внимание!</h4>

          Вы уверены, что хотите завершить интервью?
          Вы не сможете больше изменить свои оценки.

          <div class="row mt-40">
            <div class="col-auto">
              <v-button
                v-show="!isSent"
                @click="setData"
              >
                Завершить оценку
              </v-button>
            </div>
            <div class="col-auto">
              <v-button
                color="default"
                outlined
                @click="widget.showModal = false"
              >
                Отмена
              </v-button>
            </div>
          </div>
        </v-modal>
      </template>
    </l-content-widget>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import { LContentWidget, LWidget } from '@/layouts'
import {
  VCard,
  VIconSvg,
  VTab,
  VTabs,
  VAvatar
} from '@components/base'
import SvgIconNlmkInfo from '@components/icons/NLMK/SvgIconNlmkInfo'
import SvgIconNlmkAssessment from '@components/icons/NLMK/SvgIconNlmkAssessment'
import SvgIconNlmkPrewAssessment from '@components/icons/NLMK/SvgIconNlmkPrewAssessment'
import ProcessingProcess51Tab1 from './ProcessingProcess51Tab1'
import ProcessingProcess51Tab2 from './ProcessingProcess51Tab2'
import ProcessingProcess51Tab3 from './ProcessingProcess51Tab3'
import VModal from '@components/base/VModal/VModal'
import VButton from '@components/base/VButton/VButton'
import { getType } from '@utils/helpers'
import logoImg from '@assets/images/nlmk/logo.svg'

export default {
  name: 'ProcessingProcess51',

  components: {
    VButton,
    VModal,
    LContentWidget,
    LWidget,
    ProcessingProcess51Tab1,
    ProcessingProcess51Tab2,
    ProcessingProcess51Tab3,
    SvgIconNlmkAssessment,
    SvgIconNlmkInfo,
    SvgIconNlmkPrewAssessment,
    VAvatar,
    VCard,
    VIconSvg,
    VSelect,
    VTab,
    VTabs
  },

  inject: [
    'service'
  ],

  props: {
    content: Object
  },

  data () {
    return {
      logo: {
        src: logoImg
      },
      widget: {
        isSticky: window.innerWidth > 768,
        selectedAnchor: null,
        showModal: false
      },
      currentIndex: 0,
      tabs: [
        {
          id: '1',
          name: 'Общая информация',
          icon: 'Info',
          content: [
            {
              competency: 'Рекомендации к проведению интервью',
              subMenu: [
                { head: 'Вступление к интервью по компетенциям' },
                { head: 'Основная часть' },
                { head: 'Завершение интервью' }
              ]
            },
            {
              competency: 'Рекомендации к заполнению оценочной формы'
            }
          ]
        },
        {
          id: '2',
          name: 'Предыдущие оценки',
          icon: 'PrewAssessment',
          content: this.content.tab2
        },
        {
          id: '3',
          name: 'Оценка',
          icon: 'Assessment',
          content: []
        }
      ],
      isChecked: false,
      isFirstRun: true,
      isSent: false
    }
  },

  watch: {
    currentIndex () {
      localStorage.setItem(`currentTab_${this.service.event}`, this.currentIndex)
    }
  },

  computed: {
    currentTab () {
      return this.tabs[this.currentIndex]
    }
  },

  mounted () {
    let tempContent
    const LS = {
      currentTabIndex: localStorage.getItem(`currentTab_${this.service.event}`),
      tab2Data: localStorage.getItem(`tab2${this.service.event}`)
    }

    // контент берётся либо из LS либо с сервера
    if (!!LS.tab2Data && !this.isSent) {
      tempContent = JSON.parse(LS.tab2Data)
    } else {
      tempContent = JSON.parse(JSON.stringify(this.content.tab3))
      // вставляем нужные поля для оценки
      tempContent.map(content => {
        content.tables = content.tables.map(table => {
          const rank = isNaN(parseFloat(table.answer.Rank))
            ? table.answer.Rank
            : parseFloat(table.answer.Rank)
          const comment = table.answer.Comment

          return {
            ...table,
            answer: {
              rank,
              comment
            },
            error: false
          }
        })

        // todo костыль с одобрения Юры
        content.widgetRank = isNaN(parseFloat(content.widgetRank))
          ? 'Н/Д'
          : content.widgetRank

        return content
      })
    }

    if (LS.currentTabIndex) {
      this.currentIndex = +LS.currentTabIndex
    }

    this.isSent = this.content.is_sent
    this.$set(this.tabs[2], 'content', tempContent)
    this.checkData()
  },

  methods: {
    /**
     * Вставка данных "Оценки" в LS
     */
    setLsData () {
      if (this.tabs[2].content.length === 0 || this.isSent) return
      localStorage.setItem(`tab2${this.service.event}`, JSON.stringify(this.tabs[2].content))
    },

    /**
     * Удаление данных "Оценки" из LS
     */
    removeLsData () {
      delete localStorage[`tab2${this.service.event}`]
    },

    setCurrentId (index) {
      this.widget.selectedAnchor = this.tabs[index].content[0]
      this.currentIndex = index
      this.checkData()
    },

    changeSelect (selectedAnchor) {
      this.widget.selectedAnchor = selectedAnchor.competency

      this.$nextTick(() => {
        window.location.hash = `#${selectedAnchor.competency}`
      })
    },

    checkData (showErrors) {
      if (this.isSent) return

      let errors = 0

      this.tabs[2].content.forEach(content => {
        const grade = [4, 3, 2, 1]
        let indicatorsLength = content.tables.length
        let indicatorErrorsCount = 0

        let widgetRank
        let widgetAnswerCount = 0

        let sumRank = 0
        let totalRank

        let hasPlusPlus = false
        let hasPlus = false
        let hasPlusMinus = false
        let hasMinus = false

        content.commentError = false

        if (!content.comment) {
          content.commentError = true
          errors++
        }

        content.tables.forEach(table => {
          if (getType(table.answer.rank) !== 'number') {
            if (showErrors) table.error = true
            indicatorErrorsCount++
          } else {
            // оценка есть, снимаем ошибку, добавляем в расчёт
            table.error = false
            widgetAnswerCount++

            if (table.answer.rank !== 4) {
              sumRank += grade[table.answer.rank]
            }

            if (table.answer.rank === 4) {
              indicatorsLength -= 1
            }

            switch (table.answer.rank) {
              case 0:
                hasPlusPlus = true
                break
              case 1:
                hasPlus = true
                break
              case 2:
                hasPlusMinus = true
                break
              case 3:
                hasMinus = true
                break
            }
          }
        })

        errors += indicatorErrorsCount
        totalRank = Math.round((sumRank / indicatorsLength) * 2) / 2

        if ((hasPlus || hasPlusPlus) && totalRank < 2) {
          totalRank = 2
        }

        if (hasPlus && totalRank > 3.5) {
          totalRank = 3.5
        }

        if (hasPlusMinus && totalRank > 3) {
          totalRank = 3
        }

        if (hasMinus && totalRank > 2) {
          totalRank = 2
        }

        widgetRank = totalRank

        if ((indicatorsLength / content.tables.length) * 100 < 65) {
          widgetRank = 'Н/Д'
          totalRank = 'Обратите внимание, что итоговая оценка по компетенции не сформирована, т.к. вы использовали несколько вариантов «Недостаточно данных для оценки». Вернитесь, пожалуйста, к ответам кандидата и убедитесь в правильности вашего выбора.'
        }

        if (indicatorErrorsCount > 0) {
          widgetRank = '—'
          totalRank = 'Нет оценки'
        }

        content.widgetAnswerCount = widgetAnswerCount
        content.widgetRank = widgetRank
        content.rank = totalRank
      })

      this.isChecked = errors === 0

      this.setLsData()

      return errors
    },

    beforeSendData () {
      if (this.isFirstRun) {
        this.isFirstRun = false
      }

      if (this.checkData(true) === 0) {
        this.widget.showModal = true
      } else {
        console.log('Пожалуйста, поставьте все оценки')
      }
    },

    setData () {
      // типа id НазваниеКомпетенции;нормерТаблицы;rank
      // значение оценка от 1 до 5
      // типа id НазваниеКомпетенции;нормерТаблицы;comment
      // текст коммента

      const preparedData = this.tabs[2].content.reduce((result, content) => {
        const sumComment = content.comment
        const rank = content.rank
        const sum = {
          [`${content.competency};comment`]: sumComment,
          [`${content.competency};rank`]: rank
        }
        const answers = content.tables.reduce((acc, table, i) => {
          const comment = table.answer.comment
            ? { [`${content.competency};${i};comment`]: table.answer.comment }
            : {}
          return {
            [`${content.competency};${i};rank`]: table.answer.rank,
            ...comment,
            ...acc
          }
        }, {})

        return {
          ...result,
          ...answers,
          ...sum
        }
      }, {})

      this.service.sendContent(preparedData)
      this.widget.showModal = false
      this.isSent = true

      this.removeLsData()
    }
  }
}
</script>

<style lang="scss">
@import "~@assets/styles/variables";
@import "~@assets/styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

// для обратной совместимости
// с бэка приходит хардкод
.v-avatar_color_error {
  background-color: cl(danger);
}

// костыль для правильной печати
.printed-logo {
  display: none;

  &__img {
    display: block;
    width: auto;
    height: 2rem;
    padding: 0;
    margin: 0
  }
}

@media print {
  .main-container__content {
    background-color: transparent !important;

    > .container {
      margin: 0 !important;
    }
  }

  .printed-logo {
    display: block;
    margin-bottom: 24px
  }

  .hr,
  .main-container__header,
  .main-container__footer,
  .l-widget__container,
  .user-info,
  .v-tabs-list {
    display: none;
  }

  .l-content-widget_type_sticky .l-content-widget__widget {
    position: static !important;
  }

  .v-tab {
    padding: 0 !important;
  }

  .v-tabs_position_right .v-tabs__container {
    border-radius: 0;
  }

  .l-widget__header {
    border: none;
  }

  .v-tabs__container {
    background-color: #fff;
    box-shadow: none !important;
    border: none;
    border-radius: 0;
  }
}

.v-icon-svg {
  &_mode {
    &_0 {
      top: 0
    }

    &_1 {
      top: 0
    }

    &_2 {
      top: 0
    }
  }
}

.bg-color-nlmk-info {
  background-color: var(--vs-p-nlmk-51-info-color);
}

.nlmk-ca-widget-header,
.nlmk-ca-widget-main,
.nlmk-ca-widget-prefooter,
.nlmk-ca-widget-footer {
  padding: 1rem 1.5rem;

  @include media-breakpoint-down(lg) {
    padding: .75rem 1rem;
  }

  @include media-breakpoint-down(sm) {
    padding: .5rem 1rem;
  }
}

.nlmk-ca-widget-main {
  @include media-breakpoint-down(lg) {
    height: 100%;
    padding: 0;
  }
}

.nlmk-ca-widget-prefooter {
  font-size: .875rem;

  @include media-breakpoint-down(lg) {
    display: none
  }
}

.nlmk-ca-widget-footer {
  font-size: .875rem;
  font-weight: 600;
  border-radius: 0 0 br(lg) br(lg);
  cursor: pointer;
  height: 100%;
  background-color: cl(default);
  color: white;

  &_is {
    &_checked {
      background-color: cl(primary);
    }
  }

  @include media-breakpoint-up(md) {
    text-align: center;
  }

  @include media-breakpoint-down(lg) {
    text-align: right;
    border-radius: 0 br(lg) br(lg) 0;
  }

  @include media-breakpoint-down(md) {
    border-radius: 0;
  }
}
</style>
