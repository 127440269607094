<template>
  <v-expansion-panels
      :open-at-start="innerContent.open"
      flat
  >
    <v-expansion-panel>
      <v-expansion-panel-header no-icon>
        <template v-slot:default="{ open }">
          <div class="row align-items-center mb-24">
            <div class="col col-md-auto">
              <h3>{{ innerContent.head }}</h3>
            </div>

            <div
                v-if="innerContent.button_text"
                class="col-auto"
            >
              <v-button
                  size="sm"
                  @click.stop="doRequest"
              >
                {{ innerContent.button_text }}
              </v-button>
            </div>

            <div class="col-auto">
              <v-icon
                  name="evraz-chevron"
                  sprite-name="evraz"
                  fill="primary"
                  size="16"
                  :class="{'a-flip-y_active': !open}"
                  class="a-flip-y mr-4"
              />
            </div>
          </div>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <div
            v-for="(row, rowIndex) in innerContent.rows"
            :key="rowIndex"
            class="row"
        >
          <div
              v-for="(col, colIndex) in row"
              :key="colIndex"
              class="col-12 col-lg  mb-24"
          >
            <v-sheet
                outlined
                elevation="10"
                class="h-100"
            >
              <div
                  :class="{ 'bg-color-default text-light': col.disable }"
                  class="p-16"
              >
                <div class="row align-items-center">
                  <div class="col">
                    <b>{{ col.header }}</b>
                  </div>

                  <div
                      v-if="col.edit"
                      class="col-auto"
                  >
                    <v-button
                        icon
                        transparent
                        :color="col.disable ? 'light' : 'dark'"
                        @click="editBlock(col)"
                    >
                      <v-icon
                          :name="col.isEditing ? 'evraz-save' : 'evraz-edit'"
                          :fill="col.disable ? 'light' : 'dark'"
                          sprite-name="evraz"
                      />
                    </v-button>
                  </div>
                </div>

              </div>

              <v-divider
                  v-if="!col.disable"
                  class="mx-16"
              />

              <v-field
                  v-if="col.isEditing"
                  class="p-16"
              >
                <v-textarea
                    v-model="col.html"
                    autogrow
                />
              </v-field>

              <div
                  v-else
                  v-html="col.html"
                  class="p-16 evraz-content"
              />
            </v-sheet>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {
  ProcessingImgMixin,
  ProcessingRequestMixin
} from '@views/Processing/mixins'
import {
  VButton,
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VIcon,
  VSheet
} from '@components/base'
import VField from '@components/base/VField/VField'
import VTextarea from '@components/base/VField/VTextarea/VTextarea'
import { changeRespondentAttribute } from '@/api'

export default {
  name: 'ProcessingContentBlocks',

  components: {
    VTextarea,
    VField,
    VButton,
    VDivider,
    VSheet,
    VExpansionPanelContent,
    VIcon,
    VExpansionPanelHeader,
    VExpansionPanel,
    VExpansionPanels
  },

  mixins: [
    ProcessingImgMixin,
    ProcessingRequestMixin
  ],

  inject: ['service'],

  props: {
    content: Object
  },

  data () {
    return {
      innerContent: []
    }
  },

  watch: {
    content () {
      this.updateContent()
    }
  },

  created () {
    this.updateContent()
  },

  methods: {
    /** Полностью обновляет внутренний контент */
    updateContent () {
      this.setContent()
      this.setInnerProps()
    },

    /** Добавляет новый контент во внутренний избавляясь от ссылки */
    setContent () {
      this.innerContent = JSON.parse(JSON.stringify(this.content))
    },

    /** Добавляет необходимые поля во внутренний контент для необходимых манипуляций */
    setInnerProps () {
      this.innerContent.rows.forEach(row => {
        row.forEach(col => {
          if (!col.edit) return
          this.$set(col, 'isEditing', false)
        })
      })
    },

    /** Редактирование блока */
    editBlock (block) {
      if (block.isEditing) {
        changeRespondentAttribute({
          respondent_id: block.id,
          attribute_name: block.code,
          new_value: JSON.stringify({
            text: block.html
          })
        })
          .then(
            () => {
              block.isEditing = false
              this.service.getContent()
            },
            (error) => {
              console.error(error)
            }
          )
      } else {
        block.isEditing = true
      }
    }
  }
}
</script>

<style lang="scss">
.evraz {
  ul, ol {
    margin: 0;
  }
}

.evraz-content {
  table {
    width: 100%;

    tr:not(:last-child) {
      td {
        border-bottom: 1px solid cl(border-1);
      }
    }

    td {
      padding: .5rem;
    }

    td:first-child {
      padding-left: 0;
    }

    td:last-child {
      padding-right: 0;
    }
  }
}
</style>
