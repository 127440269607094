import { TableRadio } from '@components/features'
import Validatable from '@/mixins/validatable'

export default {
  components: {
    TableRadio
  },

  inject: [
    'sendData',
    'service'
  ],

  mixins: [Validatable],

  props: {
    content: Object
  },

  computed: {
    PILM_computed_showWarning () {
      return this.service.error.isShow
    }
  },

  mounted () {
    this.PILM_methods_checkValidation()
  },

  methods: {
    PILM_methods_setData (tableData) {
      if (JSON.stringify(tableData) !== JSON.stringify(this.sendData[this.content.code])) {
        this.$set(this.sendData, this.content.code, tableData)
        this.PILM_methods_checkValidation()
      }
    },

    PILM_methods_checkValidation () {
      this.checkValidationByCondition(this.sendData[this.content.code]?.length === this.content.variant.length)
    }
  }
}
