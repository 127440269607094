<template functional>
  <p
    v-html="props.content"
    class="ul ul_type_html"
  />
</template>

<script>
export default {
  name: 'ProcessingHtml',

  props: {
    content: String
  }
}
</script>
