import { VField } from '@components/base'
import Validatable from '@/mixins/validatable'

export default {
  components: {
    VField
  },

  inject: [
    'sendData',
    'sendDataAjax',
    'service'
  ],

  mixins: [Validatable],

  props: {
    content: Object
  },

  computed: {
    PTM_computed_isError () {
      return this.service.error.isShow && !this.isValid
    },
    PTM_computed_value () {
      return this.sendData[this.content.code]?.[0] || ''
    }
  },

  mounted () {
    this.PTM_methods_checkValidation(this.PTM_computed_value)
  },

  methods: {
    PTM_methods_setData (answer) {
      answer = answer.trim()

      if (this.sendData[this.content.code][0] !== answer) {
        this.$set(this.sendData, this.content.code, [answer])
      }

      // TODO DEPRECATED поддержка ajax
      if (this.content.trigger && (answer.length >= 2 || answer.length === 0)) {
        this.$set(this.sendDataAjax, 'trigger', this.content.trigger)
        this.$set(this.sendDataAjax, 'value', answer)
        this.service.getContentAjax()
      }

      this.PTM_methods_checkValidation(answer)
    },

    PTM_methods_checkValidation (value) {
      this.checkValidationByCondition(!!value)
    }
  }
}
