<template>
  <table-radio
    :show-warnings="PILM_computed_showWarning"
    :values="sendData[content.code]"
    :variant-col="content.rank_variant"
    :variant-row="content.variant"
    @change="PILM_methods_setData"
    ref="radioTable"
    unique
  >
    <template #th-first>
      <span v-html="content.placeholder"/>
    </template>
  </table-radio>
</template>

<script>
import ProcessingInputLikertMixin from './ProcessingInputLikertMixin'

export default {
  name: 'ProcessingInputLikertMostleast',

  mixins: [ProcessingInputLikertMixin],

  methods: {
    PILM_methods_checkValidation () {
      const answersLength = this.sendData[this.content.code]?.length
      this.checkValidationByCondition(
        answersLength === this.content.rank_variant.length ||
        answersLength === this.content.variant.length
      )
    }
  }
}
</script>
