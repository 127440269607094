<template>
  <v-field :error="PTM_computed_isError">
    <v-input
      :value="PTM_computed_value"
      :placeholder="content.placeholder"
      @input="PTM_methods_setData"
    />
  </v-field>
</template>

<script>
import ProcessingInputTextMixin from './ProcessingInputTextMixin'
import { VInput } from '@components/base/VField'

export default {
  name: 'ProcessingInputText',

  components: {
    VInput
  },

  mixins: [ProcessingInputTextMixin]
}
</script>
