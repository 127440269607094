<template>
  <v-tab
      :id="tab.id"
      :label="tab.name"
  >
    <v-expansion-panels open-at-start flat>
      <template v-for="(content, j) in tab.content">
        <div :key="j">
          <div v-if="j !== 0" class="py-24 print-hide">
            <hr />
          </div>

          <v-expansion-panel class="print-pbi-avoid">
            <v-expansion-panel-header no-icon>
              <template v-slot:default="{ isOpen }">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <h3
                        v-html="content.competency"
                        :id="content.competency"
                        class="text-color-primary"
                    />
                  </div>

                  <div class="col-auto">
                    <v-icon-svg
                        class="a-flip-y svg-stroke-primary"
                        :class="{'a-flip-y_active': isOpen}"
                        fill="none"
                        view-box="0 0 11 5"
                        title="svg-icon-chevron"
                        width="12"
                        height="12"
                    >
                      <svg-icon-chevron/>
                    </v-icon-svg>
                  </div>
                </div>

                <div class="row mt-16">
                  <div class="col">
                    Оценивающий: <span v-html="content.who"/>
                  </div>

                  <div class="col-auto">
                    <b>
                      {{ isNaN(content.rank) ? '' : 'Итоговая оценка:' }}
                      <span v-html="content.rank"/>
                    </b>
                  </div>
                </div>

                <div class="row mt-24">
                  <div class="col text-break">
                    <p class="text-size-12 text-color-text-secondary mb-8">Комментарий</p>
                    <p
                        v-html="content.comment"
                        class="text-size-14"
                    />
                  </div>
                </div>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div class="content-fluid">
                <div class="row mt-8">
                  <div class="col">
                    <processing-process51-tab2-table
                        v-for="(table, index) of content.tables"
                        :content="table"
                        :key="index"
                        class="mb-16 print-pbi-avoid"
                    />
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </template>
    </v-expansion-panels>
  </v-tab>
</template>

<script>
import {
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VIconSvg,
  VTab
} from '@components/base'
import SvgIconChevron from '@components/icons/SvgIconChevron'
import ProcessingProcess51Tab2Table
  from '@views/Processing/ProcessingNlmk/ProcessingProcess51Tab2Table'

export default {
  name: 'SNlmkCompetencyAssessmentTab2',

  components: {
    ProcessingProcess51Tab2Table,
    SvgIconChevron,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VIconSvg,
    VTab
  },

  props: {
    tab: Object
  }
}
</script>
