<template>
  <a
    :href="this.content.active ? content.session_uuid : null"
    :class="classes"
    class="link-like-360"
  >
    <span class="link-like-360__text">
      {{ content.text }}
    </span>

    <v-avatar
      color="success"
      class="link-like-360__icon"
    >
      <v-icon-svg
        style="left: 1px; top: 0; width: .75rem; height: .75rem;"
        stroke="none"
        fill="light"
        view-box="0 0 9 12"
        width="12"
        height="12"
      >
        <svg-icon360-play/>
      </v-icon-svg>
    </v-avatar>
  </a>
</template>

<script>
import SvgIcon360Play from '@components/icons/360/SvgIcon360Play'
import VAvatar from '@components/base/VAvatar'
import VIconSvg from '@components/base/VIconSvg'

// todo пивалось в спешке, переписать нормально
export default {
  name: 'ProcessingLink',

  components: {
    SvgIcon360Play,
    VAvatar,
    VIconSvg
  },

  props: {
    content: Object
  },

  computed: {
    classes () {
      return {
        'link-like-360_is_disabled': !this.content.active
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~@styles/tools";

.link-like-360 {
  display: flex;
  align-items: center;
  min-height: 3.875rem; // 62px
  margin: 0;
  padding: .5rem .75rem;
  transition: border-color .4s ease, background-color .3s ease;
  border-bottom: 1px solid cl(ui-3);
  text-decoration: none;

  &:hover {
    border-bottom-color: cl(primary);
  }

  &__text {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    font: bold 1rem/1.4 $font-primary;
    color: cl(text-primary)
  }

  &__icon {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin-left: 1rem;
  }

  &_is {
    &_disabled {
      user-select: none;
      cursor: default;

      &:hover {
        border-bottom-color: cl(ui-3);
      }

      .link-like-360 {
        &__text {
          color: cl(text-secondary);
        }

        &__icon {
          display: none;
        }
      }
    }
  }
}
</style>
