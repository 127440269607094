<template>
  <v-tab
    :id="tab.id"
    :label="tab.name"
    class="text-justify"
  >
    <h1
        id="Рекомендации к проведению интервью"
        class="text-color-text-secondary mb-24 text-left"
    >
      Рекомендации к проведению интервью по компетенциям
    </h1>

    <h2
        id="Вступление к интервью по компетенциям"
        class="mb-16 mt-40 text-left"
    >
      1. Вступление к интервью по компетенциям
    </h2>

    <p>
      Прежде чем вы перейдете к вопросам по компетенциям, произнесите вступительное слово:
    </p>

    <p>
      «Сейчас нам предстоит интервью по компетенциям, которое займет около 20-30 минут. Я буду задавать вопросы,
      связанные с вашей рабочей деятельностью. Попрошу отвечать на них максимально четко, поскольку времени у нас
      немного. Заранее приношу извинения, если буду вас перебивать. Я буду просить вас приводить примеры
      конкретных рабочих ситуаций и описывать свои действия. Прошу вас рассказывать именно о своих действиях,
      даже если в обсуждаемую ситуацию были вовлечены другие люди
    </p>

    <p>
      Есть ряд <b>требований к примерам ситуаций</b>. Ситуация должна быть:</p>

    <ul class="ul ul_type_point">
      <li>Актуальной (произошла за последний год, максимум два года назад);</li>
      <li>С вашим активным личным участием;</li>
      <li>Завершенной (т.е. завершилась к настоящему моменту);</li>
    </ul>

    <p>
      При этом результат ситуации может быть как позитивным, так и негативным (часто в ситуациях, которые не
      развиваются гладко, когда что-то не получается, мы проявляем свои самые сильные качества).
    </p>

    <p>
      На протяжении всего интервью я буду вести записи, они нужны для дальнейшей корректной интерпретации ваших
      ответов, постарайтесь на это не отвлекаться. Есть ли у вас вопросы сейчас? В конце интервью у вас также
      будет возможность задать мне вопросы».
    </p>

    <h2
      id="Основная часть"
      class="mb-16 mt-40 text-left"
    >
      2. Основная часть
    </h2>

    <p>Последовательно задавайте вопросы по индикаторам компетенций и фиксируйте ответы кандидата.</p>

    <p>Следует придерживаться следующих принципов в оценке:</p>

    <ul class="ul ul_type_point text-justify">
      <li>Наблюдать (слушать 80% времени, а говорить – 20% времени, применять активное слушание);</li>
      <li>
        Описывать (записывать все, что вы видите и слышите от кандидата, отмечать яркие невербальные
        проявления,
        затем – краткое резюме действий кандидата. Не записывать оценочные суждения, они мешают объективно
        оценить
        поведение кандидата);
      </li>
      <li>Классифицировать (соотносить примеры поведения с конкретными компетенциями);</li>
      <li>Оценивать (сила (яркость) проявления того или иного поведения должна быть принята в расчет).</li>
    </ul>

    <h2
      id="Завершение интервью"
      class="mb-16 mt-40 text-left"
    >
      3. Завершение интервью
    </h2>

    <p class="text-justify">
      По завершении интервью поблагодарите участника за ответы на вопросы и обсудите дальнейшие действия (когда
      будет предоставлена обратная связь, что будет происходить с кандидатом дальше).
    </p>

    <h1
      id="Рекомендации к заполнению оценочной формы"
      class="text-color-text-secondary mb-16 mt-40 text-left"
    >
      Рекомендации к заполнению оценочной формы
    </h1>

    <ol class="ol ol_type_decimal">
      <li>
        По окончании интервью оцените ответы кандидата, используя предложенную шкалу в оценочной форме. Для
        этого в каждом индикаторе компетенции выберите тот вариант оценки, который соответствует поведению и
        ответам кандидата.
      </li>
      <li>
        Итоговая оценка по компетенции будет рассчитана системой автоматически на основе ваших оценок по
        индикаторам. Обратите внимание, что балл за компетенцию будет рассчитываться только при наличии оценок
        по большинству индикаторов.
      </li>
    </ol>
  </v-tab>
</template>

<script>
import { VTab } from '@components/base'

export default {
  name: 'SNlmkCompetencyAssessmentTab1',

  components: { VTab },

  props: {
    tab: Object
  }
}
</script>
