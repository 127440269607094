<template>
  <component
      :is="componentName"
      :content="content"
      @close="onClose"
      @send="onSend"
  />
</template>

<script>
import { capitalize } from '@/filters'
import {
  ProcessingSubmitButton,
  ProcessingSubmitClipboard,
  ProcessingSubmitModal
} from '@views/Processing/ProcessingSubmit'

export default {
  name: 'ProcessingInput',

  components: {
    ProcessingSubmitButton,
    ProcessingSubmitClipboard,
    ProcessingSubmitModal
  },

  props: {
    content: Object
  },

  computed: {
    componentName () {
      return `ProcessingSubmit${capitalize(this.content.type)}${capitalize(this.content.subtype)}`
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    },

    onSend () {
      this.$emit('send')
    }
  }
}
</script>
