<template>
  <v-field :error="PTM_computed_isError">
    <v-textarea
      :value="PTM_computed_value"
      :placeholder="content.placeholder"
      @input="PTM_methods_setData"
      autogrow
    />
  </v-field>
</template>

<script>
import { VTextarea } from '@components/base'
import ProcessingInputTextMixin from './ProcessingInputTextMixin'

export default {
  name: 'ProcessingInputTextTextarea',

  components: {
    VTextarea
  },

  mixins: [ProcessingInputTextMixin]
}
</script>
