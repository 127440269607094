<template>
  <div>
    <div
      v-for="(variant, variantIndex) in variants"
      :key="variantIndex"
    >
      <div v-if="Array.isArray(variant)">
        <card-button-checkbox
          :model-value="groupsMap[variant[0].group].isActive"
          :error="variant[0].error"
          :disabled="PIC_method_isDisabled(variant[0].code)"
          @change="toggleGroup(variant[0].group, $event)"
        >
          {{ variant[0].group }}
        </card-button-checkbox>

        <div class="pl-32">
          <card-button-checkbox
            v-for="item in variant"
            :disabled="PIC_method_isDisabled(item.code)"
            :key="item.code"
            :value="item.code"
            :error="item.error"
            :model-value="PIC_computed_answers"
            @change="toggleGroupItem(item.group, $event)"
          >
            <span v-html="item.text"/>
          </card-button-checkbox>
        </div>
      </div>

      <card-button-checkbox
        v-else
        :disabled="PIC_method_isDisabled(variant.code)"
        :error="variant.error"
        :model-value="PIC_computed_answers"
        :value="variant.code"
        @change="PIC_method_changed"
      >
        {{ variant.text }}
      </card-button-checkbox>
    </div>
  </div>
</template>

<script>
import ProcessingInputCheckboxMixin from './ProcessingInputCheckboxMixin'
import { CardButtonCheckbox } from '@components/features'

export default {
  name: 'ProcessingInputCheckboxGrouped',

  mixins: [ProcessingInputCheckboxMixin],

  components: {
    CardButtonCheckbox
  },

  data () {
    return {
      variants: [],
      groupsMap: {}
    }
  },

  watch: {
    'content.variant' (variants) {
      this.fetch(variants)
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    fetch (variants = this.content.variant) {
      this.groupsMap = {}
      this.variants = this.setVariants(variants)
      this.fetchAllGroupsStatus()
    },
    /**
     * Создание удобной структуры
     * @param variants - все вариманты
     * @return {(Object | Array)[]} - Object - одиночный чекбокс, Array - группа
     */
    setVariants (variants) {
      return variants.reduce((result, variant) => {
        if (variant.group) {
          if (this.groupsMap[variant.group] === undefined) {
            result.push([])
            this.groupsMap[variant.group] = {}
            this.groupsMap[variant.group].index = result.length - 1
            this.groupsMap[variant.group].isActive = false
          }

          result[this.groupsMap[variant.group].index] = [...result[this.groupsMap[variant.group].index], variant]
          this.groupsMap[variant.group].values = [...this.groupsMap[variant.group].values || [], variant.code]
        } else {
          result.push(variant)
        }

        return result
      }, [])
    },

    /**
     * Переключение всех чекбоксов группы
     * @param {string} groupName - имя группы
     * @param {boolean} condition - нажат, или нет
     */
    toggleGroup (groupName, condition) {
      const newAnswers = condition
        ? [...new Set([...this.PIC_computed_answers, ...this.groupsMap[groupName].values])]
        : this.PIC_computed_answers.filter(x => !this.groupsMap[groupName].values.includes(x))

      this.groupsMap[groupName].isActive = condition
      this.PIC_method_checkAndSetData(newAnswers)
    },

    /**
     * Переключение чекбокса группы
     * @param {string} groupName - имя группы
     * @param {array} answers - массив ответов
     */
    toggleGroupItem (groupName, newAnswers) {
      this.PIC_method_checkAndSetData(newAnswers)
      this.fetchGroupStatus(groupName)
    },

    /**
     * Проверить статус всех групп (исп. при смене языка)
     */
    fetchAllGroupsStatus () {
      Object.keys(this.groupsMap).forEach(groupName => this.fetchGroupStatus(groupName))
    },

    /**
     * Проверка статуса одной группы
     * @param {string} groupName - имя группы
     */
    fetchGroupStatus (groupName) {
      this.groupsMap[groupName].isActive = this.groupsMap[groupName].values.every(
        value => this.PIC_computed_answers.includes(value)
      )
    }
  }
}
</script>
