<template>
  <div class="row mt-40 align-items-center">
    <div
      class="col-auto"
    >
      <v-button
        @click="closeModal"
      >
        {{ content.text }}
      </v-button>
    </div>
  </div>
</template>

<script>
import VButton from '@components/base/VButton/VButton'

export default {
  name: 'ProcessingSubmit',

  components: { VButton },

  inject: ['service'],

  props: {
    content: Object
  },

  methods: {
    closeModal () {
      this.$emit('close')
    }
  }
}
</script>
