<template>
  <drag-ranging
    :values="sendData[content.code]"
    :variants="content.variant"
    :error="showError"
    @change="setData"
  />
</template>

<script>
import { DragRanging } from '@components/features'
import Validatable from '@/mixins/validatable'

export default {
  name: 'ProcessingInputRank',

  components: {
    DragRanging
  },

  inject: [
    'sendData',
    'service'
  ],

  mixins: [Validatable],

  props: {
    content: Object
  },

  computed: {
    showError () {
      return this.service.error.isShow && !this.isValid
    }
  },

  mounted () {
    this.checkValidation()
  },

  methods: {
    setData (newAnswers) {
      const convertedAnswers = newAnswers.map(answer => answer.code)

      this.$set(this.sendData, this.content.code, convertedAnswers)
      this.checkValidation()
    },

    checkValidation () {
      this.checkValidationByCondition(this.sendData[this.content.code].length === this.content.variant.length)
    }
  }
}
</script>
