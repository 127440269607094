<template>
  <v-tab
      :id="tab.id"
      :label="tab.name"
  >
    <div class="content-fluid">
      <div
          v-for="(content, contentIndex) in tab.content"
          :id="content.competency"
          :key="contentIndex"
          class="row"
      >
        <div class="col">
          <h3
              v-html="content.competency"
              :id="content.competency"
              class="text-color-primary mb-16"
          />

          <v-sheet
              rounded
              outlined
              class="text-size-12 mb-16 p-12 bg-color-nlmk-info"
          >
            <b>Вводные вопросы к компетенции</b>
            <p
                v-html="content.intro"
                class="mb-0"
            />
          </v-sheet>

          <div class="mb-40">
            <processing-process51-tab3-table
                v-for="(table, tableIndex) of content.tables"
                v-model="tab.content[contentIndex].tables[tableIndex].answer"
                :key="tableIndex"
                :content="table"
                :isSent="isSent"
                v-on:input="$emit('tabchange')"
                class="mb-16"
            />
          </div>

          <div class="mt-40">
            <div class="row justify-content-end">
              <div class="col-auto">
                <b>
                  {{
                    typeof content.rank === 'number'
                        ? `Итоговая оценка: ${content.rank}`
                        : content.rank
                  }}
                </b>
              </div>
            </div>

            <v-field
                :error="content.commentError && !isFirstRun"
                class="mt-24 mb-40"
            >
              <label>
                Комментарий
                <sup class="text-color-danger">*</sup>
              </label>
              <v-textarea
                  v-model="content.comment"
                  v-on:input="$emit('tabchange')"
                  :disabled="isSent"
                  placeholder="Напишите комментарий"
                  autogrow
              />
            </v-field>
          </div>
        </div>
      </div>
    </div>
  </v-tab>
</template>

<script>
import {
  VField,
  VTab,
  VTextarea,
  VSheet
} from '@components/base'
import ProcessingProcess51Tab3Table
  from '@views/Processing/ProcessingNlmk/ProcessingProcess51Tab3Table'

export default {
  name: 'ProcessingProcess51Tab3',

  components: {
    VSheet,
    ProcessingProcess51Tab3Table,
    VField,
    VTab,
    VTextarea
  },

  props: {
    tab: Object,
    isSent: Boolean,
    isFirstRun: Boolean
  }
}
</script>
