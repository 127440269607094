import Validatable from '@mixins/validatable'
import { VCheckbox, VSlider } from '@components/base'
import { ProcessingExcludeIncludeAllMixin } from '@views/Processing/mixins'
import { getRootStyle } from '@utils/helpers'

export default {
  components: {
    VCheckbox,
    VSlider
  },

  inject: [
    'sendData',
    'sendDataAjax',
    'service'
  ],

  mixins: [
    Validatable,
    ProcessingExcludeIncludeAllMixin
  ],

  props: {
    content: Object
  },

  data () {
    return {
      PIRM_data_answerIndex: 0,
      PIRM_data_isPlaceholder: true,
      PIRM_data_colors: (this.content.attributes && this.content.attributes.split(',')) || getRootStyle('--color-primary')
    }
  },

  watch: {
    PIRM_computed_answer (currentAnswerCode, previousAnswerCode) {
      const currentAnswerIndex = this.PIRM_method_findAnswerIndexByCode(currentAnswerCode)
      const previousAnswerIndex = this.PIRM_method_findAnswerIndexByCode(previousAnswerCode)
      let computedAnswerIndex = currentAnswerIndex

      if (currentAnswerIndex === -1) {
        computedAnswerIndex = 0
      }

      if (this.PIRM_computed_isExcludingAll) {
        computedAnswerIndex = previousAnswerIndex === -1
          ? 0
          : previousAnswerIndex
      }

      this.PIRM_data_answerIndex = computedAnswerIndex
    },
    'service.error.isShow' () {
      if (this.isValid && this.service.error.isShow) return

      this.$set(this.content, 'error', this.service.error.isShow)
    }
  },

  computed: {
    PIRM_computed_answer () {
      return this.sendData[this.content.code][0]
    },
    PIRM_computed_purifiedVariants () {
      return this.content.variant.filter(option => !option.excludingAll && !option.includingAll)
    },
    PIRM_computed_isExcludingAll () {
      if (
        this.PIRM_computed_answer === undefined ||
        this.PEIA_computed_excludeAllCode === undefined
      ) {
        return false
      } else {
        return this.PIRM_computed_answer === this.PEIA_computed_excludeAllCode
      }
    },
    PIRM_computed_excludeAllIndex () {
      return this.content.variant.findIndex(variant => variant.code === this.PEIA_computed_excludeAllCode)
    },
    PIRM_computed_placeholderIndex () {
      if (!this.content.placeholder) {
        return -1
      }
      return this.content.variant.findIndex(variant => variant.code === this.content.placeholder)
    }
  },

  created () {
    if (this.PIRM_computed_answer) {
      this.PIRM_data_isPlaceholder = false
    }
  },

  mounted () {
    if (this.PIRM_computed_isExcludingAll) {
      this.PIRM_data_answerIndex = 0
    } else if (this.PIRM_computed_answer) {
      this.PIRM_data_answerIndex = this.PIRM_method_findAnswerIndexByCode(this.PIRM_computed_answer)
    } else if (this.content.default_value) {
      const currentIndex = this.content.variant.findIndex(variant => variant.code === this.content.default_value)
      this.PIRM_method_setData(currentIndex, true)
    }

    this.PIRM_method_checkValidation()
  },

  methods: {
    PIRM_method_findAnswerIndexByCode (code) {
      if (code === undefined) return 0
      return this.content.variant.findIndex(variant => variant.code === code)
    },
    PIRM_method_setData (currentIndex, isDefaultValue = false) {
      const answerCode = this.content.variant[currentIndex].code

      if (!isDefaultValue) {
        this.PIRM_data_isPlaceholder = false
      }

      this.$set(this.sendData, this.content.code, [answerCode])
      this.PIRM_method_checkValidation()
    },
    PIRM_method_changeExcludingAll () {
      if (this.PIRM_computed_isExcludingAll) {
        this.PIRM_method_setData(this.PIRM_data_answerIndex)
      } else {
        this.PIRM_method_setData(this.PIRM_computed_excludeAllIndex)
      }
    },
    PIRM_method_checkValidation () {
      this.checkValidationByCondition(!!this.sendData[this.content.code]?.length)
    }
  }
}
