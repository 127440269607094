<template>
  <v-button
      :link="content.theme === 'link'"
      :transparent="content.theme === 'transparent'"
      :outlined="content.theme === 'outlined'"
      :size="content.size || null"
      :color="content.color || null"
      @click="doRequest"
  >
      {{ content.value }}

      <v-icon
          v-if="content.icon"
          :name="content.icon"
          :fill="content.theme === 'link' ? 'primary' : 'light'"
      />
  </v-button>
</template>

<script>
import { VIcon } from '@components/base'
import { ProcessingRequestMixin } from '@views/Processing/mixins'
import VButton from '@components/base/VButton/VButton'

export default {
  name: 'ProcessingRequestButton',

  mixins: [
    ProcessingRequestMixin
  ],

  components: { VButton, VIcon },

  props: {
    content: Object
  }
}
</script>
