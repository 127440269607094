<template>
  <v-divider
      :theme="content.type"
      class="my-24"
  />
</template>

<script>
import VDivider from '@components/base/VDivider/VDivider'

export default {
  name: 'ProcessingLine',

  components: { VDivider },

  props: {
    content: Object
  }
}
</script>
