<template>
  <drag-ranging-chebur
    :values="values"
    :content="content"
    :error="showError"
    @change="setData"
  />
</template>

<script>
import Validatable from '@/mixins/validatable'
import DragRangingChebur from '@components/features/DragRaging/DragRangingChebur'

/**
 * Чебурашка
 */
export default {
  name: 'ProcessingInputLeftright',

  components: {
    DragRangingChebur
  },

  inject: [
    'sendData',
    'service'
  ],

  mixins: [Validatable],

  props: {
    content: Object
  },

  computed: {
    showError () {
      return this.service.error.isShow && !this.isValid
    },
    values () {
      const directionMap = {
        l: 0,
        r: 1
      }

      return this.sendData[this.content.code]?.reduce((result, current) => {
        const [key, direction] = current.split(';')

        if (direction !== 'm') {
          result[directionMap[direction]].push(key)
        }

        return result
      }, [[], []]) || [[], []]
    }
  },

  mounted () {
    this.checkValidation()
    this.setValidationMessage()
  },

  updated () {
    this.setValidationMessage()
  },

  methods: {
    setData (dndData, middleData) {
      const [leftData, rightData] = dndData
      const sendData = []

      leftData.forEach(item => sendData.push(`${item.code};l`))
      middleData.forEach(item => sendData.push(`${item.code};m`))
      rightData.forEach(item => sendData.push(`${item.code};r`))

      this.$set(this.sendData, this.content.code, sendData)
      this.checkValidation()
    },

    setValidationMessage () {
      const [leftData, rightData] = this.values
      let message = ''

      if (this.content.l_min > 0 && leftData.length < this.content.l_min) {
        message = `${this.$tc('processing.input.leftRight.error.lMin', this.content.l_min)} `
      }

      if (this.content.r_min > 0 && rightData.length < this.content.r_min) {
        message += this.$tc('processing.input.leftRight.error.rMin', this.content.r_min)
      }

      this.service.error.text = message || this.$t('processing.input.leftRight.error.matchAllAnswers')
    },

    checkValidation () {
      const [leftData, rightData] = this.values

      const isAnswerDone = leftData.length > 0 || rightData.length > 0
      const minValidation = leftData.length >= this.content.l_min && rightData.length >= this.content.r_min
      const maxValidation = leftData.length <= this.content.l_max && rightData.length <= this.content.r_max
      const isValid = isAnswerDone && minValidation && maxValidation

      this.service.validation.filled[this.content.code] = isValid

      if (!this.content.inactive) {
        this.service.validation.state[this.content.code] = !this.content.required || isValid
      }
    }
  }
}
</script>
