<template>
  <v-select
    appendToBody
    :calculate-position="PISM_methods_withPopper"
    :class="{'v-select_is_error': PISM_data_error}"
    :clearable="false"
    :multiple="content.multiple"
    :options="content.variant"
    :placeholder="content.placeholder"
    :value="PISM_computed_answers"
    @input="PISM_methods_setData"
    label="text"
  >
    <template #option="{ text }">
      <span v-html="text"/>
    </template>
    <template #selected-option="{ text }">
      <span v-html="text"/>
    </template>
    <template #open-indicator="{ attributes }">
      <svg
        v-bind="attributes"
        viewBox="0 0 10 18"
        width="1rem"
        height="1rem"
        xmlns="http://www.w3.org/2000/svg"
        role="button"
      >
        <path
          d="M1 17L8.29289 9.70711C8.68342 9.31658 8.68342 8.68342 8.29289 8.29289L1 1"
          stroke-width="2"
          stroke-linecap="round"
        ></path>
      </svg>
    </template>
  </v-select>
</template>

<script>
import ProcessingInputSelectMixin from './ProcessingInputSelectMixin'

export default {
  name: 'ProcessingInputSelectCombo',

  mixins: [ProcessingInputSelectMixin]
}
</script>
